//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  partnersSliderTop()
  partnersSliderMiddle()
  partnersSliderBottom()
  projectsSlider()
}

var partnersBreakpoints = {
  200: {
    spaceBetween: 15,
    slidesPerView: 3,
  },
  576: {
    spaceBetween: 15,
    slidesPerView: 3,
  },
  771: {
    spaceBetween: 30,
    slidesPerView: 4,
  },
  992: {
    spaceBetween: 30,
    slidesPerView: 5,
  },
  1200: {
    spaceBetween: 30,
    slidesPerView: 6,
  },
  1400: {
    spaceBetween: 30,
    slidesPerView: 7,
  },
}

function partnersSliderTop() {
  if ($('.js-slider-partners-top').length) {
    var inst = $('.js-slider-partners-top')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 20,
      lazy: true,
      loop: true,
      obswarents: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      speed: 600,
      breakpoints: partnersBreakpoints,
    })
  }
}

function partnersSliderMiddle() {
  if ($('.js-slider-partners-middle').length) {
    var inst = $('.js-slider-partners-middle')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 20,
      lazy: true,
      loop: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      speed: 600,
      breakpoints: partnersBreakpoints,
    })
  }
}

function partnersSliderBottom() {
  if ($('.js-slider-partners-bottom').length) {
    var inst = $('.js-slider-partners-bottom')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 20,
      lazy: true,
      loop: true,
      obswarents: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      speed: 600,
      breakpoints: partnersBreakpoints,
    })
  }
}

function projectsSlider() {
  if ($('.js-slider-projects').length) {
    var inst = $('.js-slider-projects'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper('.js-slider-projects', {
      lazy: true,
      // loop: true,
      // centeredSlides: true,
      // centeredSlidesBounds: true,

      // Navigation arrows
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },

      breakpoints: {
        200: {
          spaceBetween: 0,
          slidesPerView: 1,
        },
        460: {
          spaceBetween: 0,
          slidesPerView: 2,
        },
        771: {
          spaceBetween: 40,
          // slidesPerView: 3,
          slidesPerView: 'auto',
        },
        992: {
          spaceBetween: 60,
          // slidesPerView: 6,
          slidesPerView: 'auto',
          // slidesPerView: 'auto',
        },
        1200: {
          spaceBetween: 80,
          // slidesPerView: 7,
          slidesPerView: 'auto',
        },
      },
    })
  }
}
