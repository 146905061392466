//====================================================
//  Function: Animation
//====================================================
function componentAnimation() {
  if ($('.js-parallax').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax')

    new simpleParallax(parallaxImages, {
      delay: 0.8,
      scale: 1.2,
      orientation: 'down',
    })
  }

  if ($('.js-parallax-inverse').length) {
    var parallaxImagesInverse = document.querySelectorAll(
      '.js-parallax-inverse',
    )

    new simpleParallax(parallaxImagesInverse, {
      delay: 0.8,
      scale: 1.2,
      orientation: 'down',
    })
  }

  if ($('.js-parallax-bg').length) {
    var parallaxImagesBg = document.querySelectorAll('.js-parallax-bg')

    new simpleParallax(parallaxImagesBg, {
      delay: 0.8,
      scale: 1.2,
    })
  }

  if ($('.js-parallax-small-inverse').length) {
    var parallaxImagesSmallInverse = document.querySelectorAll(
      '.js-parallax-small-inverse',
    )

    new simpleParallax(parallaxImagesSmallInverse, {
      delay: 0.8,
      scale: 1.1,
    })
  }

  if ($('.js-parallax-small').length) {
    var parallaxImagesSmall = document.querySelectorAll('.js-parallax-small')

    new simpleParallax(parallaxImagesSmall, {
      delay: 0.8,
      scale: 1.1,
      orientation: 'down',
    })
  }

  // HERO HEADLINE
  if ($('.js-animate-headline').length) {
    let timelineHeadline = gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: 'back.out(1.4)',
      },
    })

    var listOptions = {
      opacity: '0',
      transform: 'translate(-500px, 0) rotateZ(0deg) rotateX(0) scale(0.6)',
    }

    gsap.utils.toArray('.js-animate-headline-item').forEach((text) => {
      timelineHeadline.from(text, listOptions)
    })
  }

  if ($('.js-animate-quotes').length && $(window).width() > bpSmall) {
    let timelineQuotes = gsap.timeline({
      defaults: {
        duration: 0.5,
        delay: 0.1,
        ease: 'back.out(1)',
      },
      scrollTrigger: {
        trigger: '.js-programs-section',
        start: 'top bottom', // when the top of the trigger hits the top of the viewport
        end: 'center center', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    })

    var listOptionsQuotes = {
      opacity: '0',
      transform: 'translate(500px, 0) rotateZ(0deg) rotateX(0) scale(0.6)',
    }

    gsap.utils.toArray('.js-animate-quotes-item').forEach((text) => {
      timelineQuotes.from(text, listOptionsQuotes)
    })
  }

  if ($('.js-robot-image').length) {
    gsap.from('.js-robot-image', {
      scrollTrigger: {
        trigger: '.js-robot-section',
        start: 'top center', // when the top of the trigger hits the top of the viewport
        end: '+=400', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      xPercent: -5,
    })
  }

  if ($('.js-ecology-hand-image').length) {
    gsap.from('.js-ecology-hand-image', {
      scrollTrigger: {
        trigger: '.js-ecology-section',
        start: 'top center', // when the top of the trigger hits the top of the viewport
        end: 'top top', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      xPercent: -20,
    })
  }

  if ($(window).width() < bpSmall) {
    var programsText = -10
  } else {
    var programsText = -35
  }

  // Parallax programs text
  if ($('.js-parallax-programs-text').length) {
    gsap.to('.js-parallax-programs-text', {
      yPercent: programsText,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-programs-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  // Parallax text
  if ($('.js-parallax-ecology-text').length) {
    gsap.to('.js-parallax-ecology-text', {
      yPercent: -15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-ecology-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-text').length) {
    gsap.to('.js-parallax-about-text', {
      yPercent: -15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-robot-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-image').length) {
    gsap.to('.js-parallax-about-image', {
      yPercent: 15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-robot-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-image-s').length) {
    gsap.to('.js-parallax-about-image-s', {
      yPercent: 15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-programs-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }
}
